/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, LoadingOverlay, Stack, Text, Title } from "@mantine/core";
import { useInterval } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { IQuestions } from "../models/QuestionResponse";
import timerDisplay from "../utils/timerDisplay";
import AnswerRow from "./AnswerRow";

interface IEndModal {
  questions: IQuestions[];
  score: number;
  timer: number;
  type?: string;
  bonusScore: number;
  availableAt?: Date;
}
enum Status {
  LOADING,
  NORMAL,
}
export default function EndModal({
  questions,
  score,
  timer,
  type,
  bonusScore,
  availableAt,
}: IEndModal) {
  const [countDownTimer, setCountDownTimer] = useState<string>();
  const [status, setStatus] = useState(Status.LOADING);
  const interval = useInterval(() => {
    if (typeof availableAt !== "undefined") {
      const now = new Date();
      const hours = availableAt.getHours() - now.getHours();
      const minutes = availableAt.getMinutes() - now.getMinutes();
      const seconds = availableAt.getSeconds() - now.getSeconds();
      setCountDownTimer(timerDisplay(hours, minutes, seconds));
    }
  }, 1000);

  useEffect(() => {
    if (typeof availableAt !== "undefined") {
      setCountDownTimer(
        timerDisplay(
          availableAt?.getHours(),
          availableAt?.getMinutes(),
          availableAt?.getSeconds()
        )
      );
      setStatus(Status.NORMAL);
      interval.start();
    }
  }, [availableAt]);
  type = type || "daily";
  return (
    <Stack gap={"lg"}>
      <LoadingOverlay visible={status === Status.LOADING && type === "daily"}></LoadingOverlay>
      <Stack align="center" justify="center" display={"flex"} gap={"lg"}>
        {type === "daily" && (
          <Stack gap={0}>
            <Text lh={1} size="xs" ta={"center"}>
              Bir Sonraki Oyun
            </Text>
            <Title lh={1} order={2} ta={"center"}>
              {countDownTimer}
            </Title>
          </Stack>
        )}
        <Stack c={"PSRGreen.9"} gap={0}>
          <Text lh={1} size="xs" ta={"center"}>
            {type === "daily" ? "Günlük Oyun Puanı" : "Toplam Puan"}
          </Text>
          <Title lh={1} order={1} ta={"center"}>
            {score + bonusScore}
          </Title>
        </Stack>
        <Flex gap={"xl"} justify={"center"}>
          <Stack gap={0}>
            <Text lh={1} size="xs" ta={"center"}>
              Doğru
            </Text>
            <Title lh={1} order={3} ta={"center"}>
              {questions.filter((q) => q.isCorrect).length}
            </Title>
          </Stack>
          <Stack gap={0}>
            <Text lh={1} size="xs" ta={"center"}>
              Puan
            </Text>
            <Title lh={1} order={3} ta={"center"}>
              {score}
            </Title>
          </Stack>
          <Flex gap={"xl"}>
            <Stack gap={0}>
              <Text lh={1} size="xs" ta={"center"}>
                Süre
              </Text>
              <Title lh={1} order={3} ta={"center"}>
                {timer}s
              </Title>
            </Stack>
            <Stack c="PSRGreen.9" gap={0}>
              <Text lh={1} size="xs" ta={"center"}>
                Bonus
              </Text>
              <Title lh={1} order={3} ta={"center"}>
                +{bonusScore || 0}
              </Title>
            </Stack>
          </Flex>
        </Flex>
        {/* <Text ta={"center"} size="sm">
          <span
            style={{
              color: "var(--mantine-color-PSRYellow-8)",
              fontWeight: 500,
            }}
          >
            {questions.filter((q) => q.isCorrect).length} kelime
          </span>{" "}
          bilerek{" "}
          <span
            style={{
              color: "var(--mantine-color-PSRGreen-8)",
              fontWeight: 500,
            }}
          >
            {score} puan
          </span>{" "}
          topladın.{" "}
          {timer > 0 ? (
            <span>
              Üstüne{" "}
              <span
                style={{
                  color: "var(--mantine-color-PSRYellow-8)",
                  fontWeight: 500,
                }}
              >
                {timer} saniye
              </span>{" "}
              artırarak{" "}
              <span
                style={{
                  color: "var(--mantine-color-PSRGreen-8)",
                  fontWeight: 500,
                }}
              >
                {bonusScore} puan
              </span>{" "}
              daha kazandın.
            </span>
          ) : (
            <span>
              Zamanının tamamını kullandığın için hiç bonus puan kazanamadın.
              Bir dahakine hızlı çözmeyi dene!
            </span>
          )}
        </Text> */}
      </Stack>
      <Stack gap={5}>
        <Title ta={"center"} order={6}>
          Cevaplar
        </Title>
        {questions.map((element, index) => (
          <AnswerRow
            key={element.word + index}
            question={element}
            order={index + 1}
          />
        ))}
      </Stack>

      {/* <Stack gap={"xs"}>
        <Text size={"sm"}>
          Her adımda, karışık verilen kelimelerin doğru yazımını bulmanız
          istenir. Bu süre zarfında jokerlerinizden yararlanabilirsiniz.
        </Text>
        <Text size={"sm"}>
          <span
            style={{
              color: "var(--mantine-color-PSRYellow-8)",
              fontWeight: 500,
            }}
          >
            Karıştır
          </span>{" "}
          jokeri harflerin sırasını karıştırarak doğru kelimeye başka açıdan
          bakmanızı sağlar.
        </Text>
        <Text size={"sm"}>
          <span
            style={{
              color: "var(--mantine-color-PSRYellow-8)",
              fontWeight: 500,
            }}
          >
            Anlamını Gör
          </span>{" "}
          jokeri doğru kelimenin sözlükteki anlam(lar)ını gösterir. Bu sayede
          cevaba bir adım daha yaklaşırsınız.
        </Text>
        <Text size={"sm"}>
          <span
            style={{
              color: "var(--mantine-color-PSRGray-5)",
              fontWeight: 500,
            }}
          >
            pas
          </span>{" "}
          yazarak veya hiçbir şey yazmadan gönder butonuna basarak soruyu
          geçebilirsiniz. Süreniz kalırsa soruya tekrar dönüp
          cevaplayabilirsiniz.
        </Text>
        <Text size={"sm"}>
          <span
            style={{
              color: "var(--mantine-color-PSRGreen-5)",
              fontWeight: 500,
            }}
          >
            Bonus Puanlar
          </span>{" "}
          2 soru art arda bildiğiniz zaman devreye girer. Soru başına 10 puan
          alırken, bonus puanlar devreye girdikten sonra bildiğiniz her soru
          başına 5 puan daha kazanırsınız. Fakat bir soruyu yanlış bilmek veya
          joker kullanmak bonus puanlarınızı sıfırlar
        </Text>
      </Stack> */}
    </Stack>
  );
}
