import CryptoJS from "crypto-js";
import { CodeError } from "../error";
import { IQuestions, QuestionResponse } from "../models/QuestionResponse";
import { ScoreResponse } from "../models/ScoreResponse";
export default class API {
  baseURL = process.env.REACT_APP_API_URL || "http://localhost:8000/api";


  async getQuestions(limit: number) {
    return await fetch(
      this.baseURL + `/words/${limit ? "?limit=" + limit : ""}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new CodeError(res.statusText, res.status);
      })
      .then((res) => {
        const bytes = CryptoJS.AES.decrypt(
          res.data,
          process.env.REACT_APP_SECRET ?? ""
        );
        const originalText = bytes.toString(CryptoJS.enc.Utf8);
        return { data: JSON.parse(originalText) };
      });
  }

  async getDailyQuestions(date?: number, uuid?: string, isAdmin?:boolean) {
    const useruuid =
      uuid || JSON.parse(window.localStorage.getItem("psr.me") || "{}").uuid;

    return await fetch(
      this.baseURL +
        `/${isAdmin ? "admin" : "words"}/daily?uuid=${useruuid}${date ? "&date=" + date : ""}`,
      {
        method: "get",
        credentials:"include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new CodeError(res.statusText, res.status);
      })
      .then((res) => {
        let data;
        if (res.data) {
          const bytes = CryptoJS.AES.decrypt(
            res.data,
            process.env.REACT_APP_SECRET ?? ""
          );
          const originalText = bytes.toString(CryptoJS.enc.Utf8);
          data = JSON.parse(originalText);
        }
        return {
          data: data as IQuestions[],
          isDailyPlayed: res.isDailyPlayed === true ? true : undefined,
          availableAt: res.availableAt as string,
        };
      });
  }

  async postDailyQuestions(questions: QuestionResponse[], date?: number) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json")
    headers.append("Cookies", window.document.cookie)
    return await fetch(
      this.baseURL + `/admin/daily${date ? "?date=" + date : ""}`,
      {
        method: "POST",
        credentials:"include",
        headers,
        body: JSON.stringify({
          questions,
        }),
      }
    ).then((res) => {
      if (res.ok) {
        return res.json();
      }
      throw new CodeError(res.statusText, res.status);
    });
  }

  async postScore(username: string, score: number, avatar: string) {
    const uuid = JSON.parse(window.localStorage.getItem("psr.me") || "{}").uuid;

    const data = CryptoJS.AES.encrypt(
      JSON.stringify({
        username,
        uuid,
        score,
        avatar,
      }),
      process.env.REACT_APP_SECRET ?? ""
    ).toString();

    return await fetch(this.baseURL + `/leaderboard`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({data:data}),
    }).then((res) => {
      if (res.ok) {
        return res.json();
      }
      throw new CodeError(res.statusText, res.status);
    });
  }

  async getScores() {
    const uuid = JSON.parse(window.localStorage.getItem("psr.me") || "{}").uuid;
    return await fetch(this.baseURL + `/leaderboard/?uuid=${uuid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new CodeError(res.statusText, res.status);
      })
      .then((res) => {
        return {
          data: res.data as ScoreResponse[],
          isDailyPlayed: res.isDailyPlayed as boolean,
          availableAt: res.availableAt as string,
        };
      });
  }

  async login(username: string, password: number) {
    return await fetch(this.baseURL + "/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        password,
      }),
    }).then((res) => {
      if (res.ok) {
        return res.json();
      }
      throw new CodeError(res.statusText, res.status);
    });
  }

  async checkToken() {
    const headers = new Headers();
    headers.append("Content-Type", "application/json")
    headers.append("Cookies", window.document.cookie)
    return await fetch(this.baseURL + "/auth", {
      method: "GET",
      credentials:"include",
      headers
    }).then((res) => {
      if (res.ok) {
        return res.json();
      }
      throw new CodeError(res.statusText, res.status);
    });
  }

  async getRandom() {
    return await fetch(this.baseURL + `/words/rand`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new CodeError(res.statusText, res.status);
      })
      .then((res) => {
        return {
          data: res.data as QuestionResponse,
        };
      });
  }
}
