export default function timerDisplay(
  hours?: number,
  minutes?: number,
  seconds?: number
): string {
  return `${
    typeof hours !== "undefined" ? timerDisplaySingle(hours) + ":" : ""
  }${typeof minutes !== "undefined" ? timerDisplaySingle(minutes) : ""}${
    typeof seconds !== "undefined" ? ":" + timerDisplaySingle(seconds) : ""
  }`;
}

const timerDisplaySingle = (timer: number): string => {
  return `${Array(2 - timer.toString().length)
    .fill(0)
    .map(() => "0")}${timer}`;
};
