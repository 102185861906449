import { HashRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Authenticator from "./Authenticator";
import AdminPanel from "./pages/Admin";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Playground from "./pages/Playground";
import Tour from "./pages/Tour";
function App() {

  return (
    <HashRouter
      window={window}
      basename="/"
      future={{ v7_startTransition: true }}
    >
      <Authenticator />
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/game/:type" Component={Playground} />
        <Route path="/admin" Component={AdminPanel} />
        <Route path="/login" Component={Login} />
        <Route path="/tour" Component={Tour} />
      </Routes>
    </HashRouter>
  );
}

export default App;
