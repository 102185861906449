/* eslint-disable react-hooks/exhaustive-deps */
import {
  ActionIcon,
  AppShell,
  Avatar,
  Button,
  Center,
  Flex,
  Group,
  Image,
  Indicator,
  LoadingOverlay,
  Modal,
  Paper,
  Popover,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure, useInterval } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import {
  IconArrowsShuffle2,
  IconCalendar,
  IconMedal,
  IconQuestionMark,
  IconSchool,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../api/api";
import LeaderBoardModal from "../components/LeaderBoardModal";
import StartModal from "../components/StartModal";
import logo from "../images/logo.svg";
import { ScoreResponse } from "../models/ScoreResponse";
import { avatarURL, avatars } from "../utils/avatar";
import timerDisplay from "../utils/timerDisplay";
enum Status {
  LOADING,
  NORMAL,
}
export default function Home() {
  const form = useForm({
    initialValues: {
      ...JSON.parse(window.localStorage.getItem("psr.me") ?? "{}"),
    },
  });
  const [openedInfo, { open: openInfo, close: closeInfo }] = useDisclosure();
  const [status, setStatus] = useState(Status.LOADING);
  const [scores, setScores] = useState<ScoreResponse[]>();
  const [opened, { open, close }] = useDisclosure(false);
  const [isDailyPlayed, setIsDailyPlayed] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [availableAt, setAvailableAt] = useState<Date>();
  const [countDownTimer, setCountDownTimer] = useState<string>();
  const interval = useInterval(() => {
    if (typeof availableAt !== "undefined") {
      const now = new Date();
      const hours = availableAt.getHours() - now.getHours();
      const minutes = availableAt.getMinutes() - now.getMinutes();
      const seconds = availableAt.getSeconds() - now.getSeconds();
      setCountDownTimer(timerDisplay(hours, minutes, seconds));
    }
  }, 1000);
  const navigate = useNavigate();
  const api = new API();
  useEffect(() => {
    if (typeof availableAt !== "undefined") {
      setCountDownTimer(
        timerDisplay(
          availableAt?.getHours(),
          availableAt?.getMinutes(),
          availableAt?.getSeconds()
        )
      );
      interval.start();
    }
  }, [availableAt]);

  useEffect(() => {
    fetchLeaderBoard();
  }, []);

  const saveHandler = async () => {
    form.resetDirty();
    window.localStorage.setItem(
      "psr.me",
      JSON.stringify({
        ...JSON.parse(window.localStorage.getItem("psr.me") ?? "{}"),
        avatar: form.getInputProps("avatar").value,
        username: form.getInputProps("username").value,
      })
    );
    notifications.show({
      title: "Değişiklikler Kaydedildi",
      message:
        "Yaptığınız değişiklikler bir sonraki günlük oyun sonucunda skor tablosuna etki edecektir.",
      autoClose: 5000,
    });
  };

  const fetchLeaderBoard = async () => {
    setIsFetching(true);
    await api
      .getScores()
      .then((res) => {
        setIsDailyPlayed(res.isDailyPlayed);
        setScores(res.data);
        const tempData = JSON.parse(
          window.localStorage.getItem("psr.me") ?? "{}"
        );
        form.setValues({ ...tempData });
        form.setInitialValues({ ...tempData });
        form.resetDirty();
        if (res.availableAt) {
          setAvailableAt(new Date(res.availableAt));
        }
      })
      .catch(() => {
        notifications.show({
          title: "Sunucuyla Bağlantı Kurulamadı",
          message:
            "Sunucuyla bağlantı kurulamadı. Lütfen internet bağlantınızı kontrol edip daha sonra tekrar deneyiniz.",
          color: "red",
        });
      })
      .finally(() => {
        setIsFetching(false);
        setStatus(Status.NORMAL);
      });
  };

  return (
    <AppShell>
      <AppShell.Main>
        <LoadingOverlay visible={status === Status.LOADING}></LoadingOverlay>
        <Modal
          title={"👋 Hoşgeldin"}
          centered
          withCloseButton={false}
          opened={
            form.getInputProps("isFirstTime").value === true &&
            status === Status.NORMAL
          }
          onClose={() => {}}
        >
          <Stack>
            Senin için hazırladığımız temel bilgileri öğreten öğretici oyunu
            oynamak ister misin?
            <Stack gap={5}>
              <Button
                onClick={() => {
                  // setMe((prev) => {
                  //   return { ...prev, isFirstTime: false };
                  // });
                  navigate("tour");
                }}
              >
                Öğretici Oyunu Oyna
              </Button>
              <Button
                onClick={() => {
                  window.localStorage.setItem(
                    "psr.me",
                    JSON.stringify({
                      ...JSON.parse(
                        window.localStorage.getItem("psr.me") ?? "{}"
                      ),
                      isFirstTime: false,
                    })
                  );
                  form.setFieldValue("isFirstTime", false);
                }}
                size="xs"
                variant="transparent"
              >
                Öğreticiyi Atla
              </Button>
            </Stack>
          </Stack>
        </Modal>
        <Modal withCloseButton={false} opened={openedInfo} onClose={closeInfo}>
          <Stack>
            <StartModal type={"home"} />
            <Button onClick={closeInfo} variant="subtle">
              Anladım
            </Button>
          </Stack>
        </Modal>
        <LeaderBoardModal
          isRefreshLoading={isFetching}
          onRefresh={fetchLeaderBoard}
          scores={scores}
          modalProps={{ opened, onClose: close }}
        />
        <Center h={"100dvh"}>
          <Stack>
            <Paper bg={"dark.8"} pt={"lg"} p={"xl"} shadow="xl">
              <Stack gap={"sm"}>
                <Flex w={"100%"} justify={"space-between"} align={"center"}>
                  <Indicator disabled>
                    <ActionIcon onClick={openInfo} size={"xs"} radius={"xl"}>
                      <IconQuestionMark />
                    </ActionIcon>
                  </Indicator>
                  <Popover width={300} position="top-end" withArrow shadow="xl">
                    <Popover.Target>
                      <Group>
                        <Indicator disabled={!form.isDirty()}>
                          <ActionIcon
                            radius={"xl"}
                            size={"md"}
                            variant="transparent"
                          >
                            <Avatar
                              src={avatarURL(
                                form.getInputProps("avatar").value
                              )}
                              size={"sm"}
                              pt={0}
                            ></Avatar>
                          </ActionIcon>
                        </Indicator>
                      </Group>
                    </Popover.Target>
                    <Popover.Dropdown bg={"dark.9"} p={"xs"}>
                      <Stack p={"xs"}>
                        <Stack gap={5}>
                          <Text size="sm" fw={500}>
                            Avatar
                          </Text>
                          <Flex
                            wrap={"wrap"}
                            justify={"space-between"}
                            gap={"xs"}
                          >
                            {avatars.map((src, index) => (
                              <Avatar
                                key={src + index}
                                onClick={() => {
                                  form.setFieldValue("avatar", src);
                                }}
                                styles={
                                  form.getInputProps("avatar").value === src
                                    ? {
                                        root: {
                                          boxShadow:
                                            "0px 0px 0px 3px var(--mantine-color-PSRYellow-5)",
                                        },
                                      }
                                    : {}
                                }
                                src={avatarURL(src)}
                              ></Avatar>
                            ))}
                          </Flex>
                        </Stack>

                        <TextInput
                          maxLength={10}
                          pb={"xs"}
                          label={"Kullanıcı Adı"}
                          {...form.getInputProps("username")}
                        ></TextInput>
                        <Stack gap={"xs"}>
                          <Button
                            disabled={!form.isDirty()}
                            size="xs"
                            variant="outline"
                            onClick={() => {
                              form.reset();
                            }}
                          >
                            Sıfırla
                          </Button>

                          <Button
                            size="xs"
                            onClick={saveHandler}
                            disabled={!form.isDirty()}
                          >
                            Kaydet
                          </Button>
                        </Stack>
                      </Stack>
                    </Popover.Dropdown>
                  </Popover>
                </Flex>

                <Stack
                  pt={0}
                  p={"md"}
                  gap={"xl"}
                  justify="center"
                  display={"flex"}
                  align="center"
                >
                  <Image src={logo} w={180}></Image>
                  <Stack>
                    <Indicator
                      disabled={!isDailyPlayed}
                      w={"100%"}
                      label={isDailyPlayed && countDownTimer}
                      size={16}
                    >
                      <Button
                        fullWidth
                        variant={isDailyPlayed ? "outline" : "filled"}
                        onClick={() => {
                          navigate("game/daily");
                        }}
                        leftSection={<IconCalendar />}
                      >
                        Günlük Oyun
                      </Button>
                    </Indicator>

                    <Button
                      variant="filled"
                      onClick={open}
                      leftSection={<IconMedal />}
                    >
                      {" "}
                      Günlük Oyun Skor Tablosu
                    </Button>
                    <Button
                      onClick={() => {
                        navigate("game/playground");
                      }}
                      variant="filled"
                      leftSection={<IconArrowsShuffle2 />}
                    >
                      Normal Oyun
                    </Button>
                    <Button
                      onClick={() => {
                        navigate("tour");
                      }}
                      variant="filled"
                      leftSection={<IconSchool />}
                    >
                      Öğretici Oyun
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </Paper>
            <Text size="sm" ta={"center"} fs={"italic"} c={"dimgray"}>
              <a style={{color:"dimgray"}} href="https://tscburak.dev"> tscburak</a> tarafından tasarlandı ve geliştirildi
            </Text>
          </Stack>
        </Center>
      </AppShell.Main>
    </AppShell>
  );
}
