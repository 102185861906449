const consonants = [
  "b",
  "c",
  "d",
  "f",
  "g",
  "h",
  "j",
  "k",
  "l",
  "m",
  "n",
  "p",
  "q",
  "r",
  "s",
  "t",
  "v",
  "w",
  "x",
  "v",
  "z",
];
const vowels = ["a", "e", "i", "o", "u"];
export default function usernameGenerator() {
  const usernameLength = Math.floor(Math.random() * 3) + 5;
  let username = "";
  for (let i = 0; i < usernameLength; i++) {
    if (Math.random() < 0.8) {
      if (i % 2 === 0) {
        username += consonants[Math.floor(Math.random() * consonants.length)];
      } else {
        username += vowels[Math.floor(Math.random() * vowels.length)];
      }
    } else if (Math.random() < 0.3) {
      username += vowels[Math.floor(Math.random() * vowels.length)];
    } else {
      username += consonants[Math.floor(Math.random() * consonants.length)];
    }
  }
  return username;
}
