const shuffle = (text: string) => {
  let splittedText = text.split("");
  let shuffledText = "";
  const fletter = splittedText[0];
  if (
    splittedText.filter((l) => l === fletter).length === splittedText.length
  ) {
    shuffledText += splittedText.join("");
    return shuffledText;
  }
  while (splittedText.length > 0) {
    const random = Math.floor(Math.random() * splittedText.length);
    shuffledText += splittedText[random];
    splittedText.splice(random, 1);
    if (shuffledText === text) {
      splittedText = text.split("");
      shuffledText = "";
    }
  }
  return shuffledText;
};

export default shuffle;
