import { Avatar, Flex, Group, Text } from "@mantine/core";
import { avatarURL } from "../utils/avatar";

interface ILeaderBoardUser {
  username?: string;
  avatar?: string;
  score?: number;
  order?: number;
  isMe?: boolean;
  variant?: "row" | "title";
}
export default function LeaderBoardUser({
  username,
  avatar,
  score,
  isMe,
  order,
  variant,
}: ILeaderBoardUser) {
  let body = <></>;

  switch (variant || "row") {
    case "row":
      body = (
        <Flex
          w={"100%"}
          justify={"space-between"}
          align={"center"}
          gap={"xl"}
          c={isMe ? "PSRYellow" : ""}
        >
          <Group>
            <Text>{order}.</Text>
            <Avatar src={avatar ? avatarURL(avatar) : ""}></Avatar>
          </Group>

          <Text>{username}</Text>
          <Text>{score} puan</Text>
        </Flex>
      );
      break;

    default:
      break;
  }
  return body;
}
