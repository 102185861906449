/* eslint-disable react-hooks/exhaustive-deps */
import {
  ActionIcon,
  Box,
  Button,
  Center,
  Flex,
  Group,
  LoadingOverlay,
  Modal,
  PinInput,
  Popover,
  RingProgress,
  ScrollArea,
  Stack,
  Text,
  TextInput,
  Tooltip,
  em,
} from "@mantine/core";
import {
  useInterval,
  useListState,
  useMediaQuery,
  useScrollIntoView,
} from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import {
  IconArrowForward,
  IconArrowsShuffle,
  IconVocabulary,
  IconX,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../api/api";
import EndModal from "../components/EndModal";
import StartModal from "../components/StartModal";
import { IQuestions } from "../models/QuestionResponse";
import shuffle from "../utils/shuffle";
import timerDisplay from "../utils/timerDisplay";
const GROW = 10;
const DURATION = 240;

enum Status {
  ERROR,
  LOADING,
  NOT_STARTED,
  STARTED,
  ENDED,
  DAILY_ENDED,
}

export default function Playground(): JSX.Element {
  const { type } = useParams();
  const [questions, questionHandler] = useListState<IQuestions>([]);
  const [addedScore, setAddedScore] = useState(GROW);
  const [current, setCurrent] = useState(0);
  const [answer, setAnswer] = useState("");
  const [inputNotifColor, setInputNotifColor] = useState<string | undefined>();
  const [score, setScore] = useState(0);
  const [stack, setStack] = useState(-1);
  const [status, setStatus] = useState(Status.LOADING);
  const [startLoading, setStartLoading] = useState(false);
  const [countDown, setCountDown] = useState(DURATION);
  const [availableAt, setAvailableAt] = useState<Date>();
  const [timer, setTimer] = useState<string>("04:00");
  const [bonusScore, setBonusScore] = useState(0);
  const [openedMeaningsPopover, setMeaningsPopover] = useState(false);
  // const [showMeaningsModal, { toggle }] = useDisclosure(true);
  const me = JSON.parse(window.localStorage.getItem("psr.me") || "{}");
  const interval = useInterval(() => setCountDown((s) => s - 1), 1000);
  const isSmallScreen = useMediaQuery(`(max-width: ${em(750)})`);
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLInputElement>({
    offset: 60,
  });
  const api = new API();

  useEffect(() => {
    if (!["playground", "daily"].includes(type as string)) {
      window.location.href = "#";
      return;
    }
    fetchQuestions();
  }, []);

  useEffect(() => {
    setAddedScore(GROW + (stack <= 0 ? 0 : stack * 5));
  }, [stack]);

  const scrollToTop = () => {
    setTimeout(() => {
      scrollIntoView({
        alignment: "end",
      });
    }, 400);
  };

  const fetchQuestions = async () => {
    setStartLoading(true);
    setStatus(Status.LOADING);
    switch (type) {
      case "playground":
        return api
          .getQuestions(15)
          .then((res) => {
            if (res.data.length === 0) {
              throw Error();
            }
            setStatus(Status.NOT_STARTED);
            setStartLoading(false);
            questionHandler.setState(res.data);
          })
          .catch(() => {
            setStatus(Status.ERROR);
            setStartLoading(false);
          });
      case "daily":
        return api
          .getDailyQuestions()
          .then((res) => {
            setStartLoading(false);
            if (res.data.length === 0) {
              throw Error();
            }
            if (res.isDailyPlayed) {
              const dailyData = JSON.parse(
                window.localStorage.getItem("psr.daily") ?? "[]"
              );
              if (dailyData.data.length > 0) {
                questionHandler.setState(
                  res.data.map((q, index) => {
                    return { ...q, isCorrect: dailyData.data[index].isCorrect };
                  })
                );
              }
              setCountDown(dailyData.countdown);
              setScore(dailyData.score);
              if (res.availableAt) {
                setAvailableAt(new Date(res.availableAt));
              }
              if (
                dailyData.data.filter((q: IQuestions) => !q.isCorrect)
                  .length === 0
              ) {
                let bonus = Math.ceil(dailyData.countdown / 5);
                setBonusScore(bonus);
              }
              setStatus(Status.DAILY_ENDED);
            } else {
              setStatus(Status.NOT_STARTED);
              questionHandler.setState(res.data);
            }
          })
          .catch(() => {
            setStatus(Status.ERROR);
            setStartLoading(false);
          });
      default:
        break;
    }
  };

  useEffect(() => {
    if (countDown === 0 && status === Status.STARTED) {
      setStatus(Status.ENDED);
    } else if (status === Status.STARTED) {
      const minutes = Math.floor(countDown! / 60);
      const seconds = Math.floor(countDown! % 60);
      setTimer(timerDisplay(minutes, seconds));
    }
  }, [countDown]);

  useEffect(() => {
    if (questions[current]?.isCorrect) {
      let temp = current;
      setCurrent(temp + 1);
    }
    if (current === (questions?.length || 0) && current !== 0) {
      if (questions.filter((question) => !question.isCorrect).length === 0) {
        setStatus(Status.ENDED);
      }
      setCurrent(questions?.findIndex((question) => !question.isCorrect));
    }
    setMeaningsPopover(false);
  }, [current]);

  useEffect(() => {
    if (status === Status.ENDED) {
      interval.stop();
      let bonus = bonusScore;
      if (questions.filter((q) => !q.isCorrect).length === 0) {
        bonus = Math.ceil(countDown / 5);
        setBonusScore(bonus);
      }
      if (type === "daily") {
        api
          .postScore(me.username, bonus + score, me.avatar)
          .then((res) => {
            window.localStorage.setItem(
              "psr.daily",
              JSON.stringify({
                data: questions,
                score: score,
                countdown: countDown,
                lastCompleted: new Date().getTime(),
              })
            );
            if (res.availableAt) {
              setAvailableAt(new Date(res.availableAt));
            }
            setStatus(Status.DAILY_ENDED);
          })
          .catch(() => {
            notifications.show({
              title: "Skorunuz kaydedilemedi",
              message: "Skorunuz kaydedilirken bir hatayla karşılaşıldı.",
              color: "red",
            });
            window.location.href = "#";
          });
      }
    }
  }, [status]);

  const checkHandler = () => {
    if (
      answer.toLocaleLowerCase("tr") ===
      questions[current]?.word.toLocaleLowerCase("tr")
    ) {
      setInputNotifColor("var(--mantine-color-PSRGreen-5)");
      setCurrent((prev) => prev + 1);
      setScore((prev) => prev + addedScore);
      setStack((prev) => (prev < 4 ? prev + 1 : prev));
      questionHandler.setItemProp(current, "isCorrect", true);
    } else if (answer.toLowerCase() === "pas" || answer.toLowerCase() === "") {
      setInputNotifColor("var(--mantine-color-dark-2)");
      setCurrent((prev) => prev + 1);
    } else if (answer.toLocaleLowerCase("tr") === "bitir") {
      openEndModal();
    } else if (answer.trim() === "1") {
      shuffleHandler();
    } else if (answer === "2") {
      meaningsHandler();
    } else {
      setInputNotifColor("var(--mantine-color-PSRRed-5)");
      setStack(-1);
    }
    setAnswer("");
    setTimeout(() => {
      setInputNotifColor(undefined);
    }, 1000);
  };

  const meaningsHandler = () => {
    if (!(questions[current].areMeaningsVisible || false)) {
      setStack(stack > 0 ? -1 : stack);
      questionHandler.setItemProp(current, "areMeaningsVisible", true);
    }
    setMeaningsPopover(true);
  };

  // const openMeaningsHandlerModal = () =>
  //   modals.openConfirmModal({
  //     title: "Bilgilendirme",
  //     children: (
  //       <Stack>
  //         <Text size="sm">
  //           Bu jokeri kullanmanız halinde bonus puanınız (+
  //           {stack <= 0 ? 0 : stack * 5} puan) sıfırlanır.
  //         </Text>
  //         <Checkbox onChange={toggle} size="xs" label={"Bir daha gösterme"} />
  //       </Stack>
  //     ),
  //     labels: { confirm: "Evet", cancel: "Hayır" },
  //     onCancel: () => {},
  //     onConfirm: () => {
  //       meaningsHandler();
  //     },
  //   });

  const shuffleHandler = () => {
    const temp = questions[current]?.shuffled;
    questionHandler.setItemProp(current, "shuffled", shuffle(temp));
  };

  const openEndModal = () =>
    modals.openConfirmModal({
      title: "Oyunu Sonlandırıyorsunuz",
      children: (
        <Text size="sm">Oyunu sonlandırmak istediğinize emin misiniz?</Text>
      ),
      labels: { confirm: "Evet", cancel: "Hayır" },
      onCancel: () => {},
      onConfirm: () => {
        setStatus(Status.ENDED);
      },
    });

  const AnswerButton = (): JSX.Element => {
    const props = {
      size: isSmallScreen ? "md" : "lg",
      style: { transition: "all 150ms" },
      onClick: () => {
        checkHandler();
      },
      onPointerDown: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
      ) => {
        event.preventDefault();
      },
      variant: "filled",
      p: 0,
    };

    switch (answer.toLocaleLowerCase("tr").trim()) {
      case "bitir":
        return (
          <ActionIcon {...props} color="PSRRed.5">
            <IconX />
          </ActionIcon>
        );
      case "1":
        return (
          <ActionIcon {...props} color="PSRGreen">
            <IconArrowsShuffle />
          </ActionIcon>
        );
      case "2":
        return (
          <ActionIcon {...props} color="PSRGreen">
            <IconVocabulary />
          </ActionIcon>
        );
      case "":
      case "pas":
        return (
          <ActionIcon {...props} color="dark.3">
            <Text fw={600} size="xs">
              PAS
            </Text>
          </ActionIcon>
        );
      default:
        return (
          <ActionIcon {...props}>
            <IconArrowForward />
          </ActionIcon>
        );
    }
  };
  const startHandler = async () => {
    setCountDown(DURATION);
    interval.start();
    setStatus(Status.STARTED);
    setCurrent(0);
    setScore(0);
    setStack(-1);
  };

  return (
    <div>
      <LoadingOverlay visible={status === Status.LOADING}></LoadingOverlay>
      <Modal
        opened={status === Status.ERROR}
        onClose={() => {}}
        withCloseButton={false}
        centered
        title={"Sorular Yüklenemedi"}
      >
        <Stack>
          <Text size="sm">Sorular yüklenirken bir hatayla karşılaşıldı. </Text>
          <Group wrap="nowrap" gap={"md"} w={"100%"}>
            <Button
              onClick={() => {
                window.location.href = "#";
              }}
              size="sm"
              variant="outline"
              fullWidth
            >
              Geri
            </Button>
            <Button
              onClick={() => {
                fetchQuestions();
              }}
              size="sm"
              fullWidth
            >
              Tekrar Dene
            </Button>
          </Group>
        </Stack>
      </Modal>
      <Modal
        opened={status === Status.NOT_STARTED}
        withCloseButton={false}
        onClose={() => {}}
        title={<Text fw={700}>Oyunu Başlat</Text>}
        scrollAreaComponent={ScrollArea.Autosize}
      >
        <Stack>
          <StartModal type={type as "daily" | "playground" | "home"} />
          <Button loading={startLoading} onClick={startHandler}>
            Oyunu Başlat
          </Button>
          <Button variant="outline" color="dark" component="a" href="/">
            Geri
          </Button>
        </Stack>
      </Modal>
      <Modal
        opened={status === Status.ENDED || status === Status.DAILY_ENDED}
        withCloseButton={false}
        onClose={() => {}}
        title={<Text fw={700}>Sonuçlar</Text>}
        scrollAreaComponent={ScrollArea.Autosize}
      >
        <Stack>
          <EndModal
            questions={questions}
            score={score}
            timer={DURATION - countDown}
            type={type}
            bonusScore={bonusScore}
            availableAt={availableAt}
          />
          {status !== Status.DAILY_ENDED && (
            <Button
              onClick={() => {
                fetchQuestions();
              }}
            >
              Tekrar Oyna
            </Button>
          )}

          <Button variant="outline" color="dark" component="a" href="/">
            Ana Sayfaya Dön
          </Button>
        </Stack>
      </Modal>
      <Flex pl={"xl"} pr={"xl"} h={"100dvh"} justify={"center"}>
        <Stack
          w={"500px"}
          gap={isSmallScreen ? 0 : "md"}
          align="center"
          h={"100%"}
        >
          <Flex justify={"center"} align={"center"}>
            <RingProgress
              pt={isSmallScreen ? "xs" : "xl"}
              m={"lg"}
              roundCaps
              label={
                <Center h={isSmallScreen ? 65 : 110}>
                  <Stack mt={"auto"} gap={0}>
                    <Text lh={"xs"} size="xs">
                      Soru
                    </Text>
                    <Text lh={"xs"} mt={"auto"} size={"xl"} ta="center">
                      {current + 1}
                    </Text>
                  </Stack>
                </Center>
              }
              sections={
                questions.length > 0
                  ? questions.map((question, index, array) => {
                      return {
                        value: 100 / array.length,
                        color:
                          current === index
                            ? "dark.2"
                            : question.isCorrect
                            ? "PSRGreen"
                            : "dark.0",
                      };
                    })
                  : [{ value: 100, color: "dark.0" }]
              }
            />
          </Flex>
          <Text>{timer}</Text>
          <Group ta={"center"}>
            <Text
              styles={{
                root: {
                  display: "inline-block",
                  position: "relative",
                  transition: "all 100ms",
                },
              }}
              c={
                typeof inputNotifColor === "string" ? inputNotifColor : "dark.0"
              }
              size={"45px"}
              ta={"center"}
            >
              {score}
            </Text>
            <Text
              c={`PSRGreen.${5 + stack < 10 ? 5 + stack : 9}`}
              styles={{
                root: {
                  position: "absolute",
                  marginLeft: score.toString().length * 27,
                },
              }}
              ta={"right"}
              size="17px"
            >
              +{addedScore}
            </Text>
          </Group>
          <Stack mt={"5dvh"} h={"100%"} gap={"xl"}>
            <Stack justify="center" align="center">
              <PinInput
                ref={targetRef}
                placeholder=""
                defaultValue="PÜSÜR"
                gap={5}
                size={isSmallScreen ? "md" : "xl"}
                value={questions[current]?.shuffled.toLocaleUpperCase("tr")}
                length={questions[current]?.shuffled.length ?? 5}
                readOnly
              ></PinInput>
              <Group>
                <Tooltip position="left" label={"Karıştır"}>
                  <ActionIcon
                    variant="outline"
                    color="PSRGreen"
                    size={isSmallScreen ? "md" : "lg"}
                    onPointerDown={(event) => {
                      event.preventDefault();
                    }}
                    onClick={shuffleHandler}
                  >
                    <IconArrowsShuffle />
                  </ActionIcon>
                </Tooltip>
                <Popover
                  opened={openedMeaningsPopover}
                  onChange={setMeaningsPopover}
                  width={300}
                  position="bottom"
                  withArrow
                  shadow="md"
                >
                  <Popover.Target>
                    <Tooltip position="right" label={"Anlamını gör"}>
                      <ActionIcon
                        color="PSRGreen"
                        variant={
                          questions[current]?.areMeaningsVisible || false
                            ? "outline"
                            : "filled"
                        }
                        onClick={(event) => {
                          event.stopPropagation();
                          meaningsHandler();
                        }}
                        onPointerDown={(event) => {
                          event.preventDefault();
                        }}
                        size={isSmallScreen ? "md" : "lg"}
                      >
                        <IconVocabulary />
                      </ActionIcon>
                    </Tooltip>
                  </Popover.Target>
                  <Popover.Dropdown
                    onClick={(event) => {
                      console.log(event);
                      event.preventDefault();
                    }}
                    onKeyDown={(event) => {
                      event.preventDefault();
                    }}
                  >
                    {(questions[current]?.areMeaningsVisible || false) && (
                      <Box
                        pl={"sm"}
                        pr={"sm"}
                        component={ScrollArea}
                        mah={isSmallScreen ? 150 : 140}
                      >
                        {questions[current]?.meanings.map(
                          (meaning, index, array) => (
                            <Text
                              key={meaning.trim().replaceAll(" ", "_")}
                              size={isSmallScreen ? "xs" : "sm"}
                              ta={"center"}
                            >
                              {array.length > 1 ? `${index + 1} - ` : ""}
                              {meaning}
                            </Text>
                          )
                        )}
                      </Box>
                    )}
                  </Popover.Dropdown>
                </Popover>
              </Group>
            </Stack>
            <TextInput
              style={{
                position: "absolute",
                top: (window.visualViewport?.height || window.innerHeight) - 60,
                marginLeft: "auto",
                left: 0,
                right: 0,
                marginRight: "auto",
                width: 300,
              }}
              mb={"md"}
              size="md"
              onKeyDown={(event) => {
                setMeaningsPopover(false);
                if (event.key === "Enter") {
                  checkHandler();
                }
              }}
              onFocus={(event) => {
                scrollToTop();
                event.preventDefault();
              }}
              onChange={(event) => setAnswer(event.target.value.trim())}
              value={answer}
              rightSection={<AnswerButton />}
              placeholder="Pas"
              styles={{
                input: {
                  border:
                    typeof inputNotifColor === "string"
                      ? `1px ${inputNotifColor} solid`
                      : "",
                  transition: "all 100ms ease-out",
                },
              }}
              ta={"center"}
              autoFocus
            ></TextInput>
          </Stack>
        </Stack>
      </Flex>
    </div>
  );
}
