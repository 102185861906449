import { Button, Modal, ModalProps, Stack } from "@mantine/core";
import { ScoreResponse } from "../models/ScoreResponse";
import LeaderBoardUser from "./LeaderBoardUser";

interface ILeaderBoardModal {
  scores?: ScoreResponse[];
  onRefresh?: () => void;
  isRefreshLoading?:boolean;
  modalProps: ModalProps;
}
export default function LeaderBoardModal({
  modalProps,
  scores,
  onRefresh,
  isRefreshLoading
}: ILeaderBoardModal) {
  return (
    <Modal title="Günlük Oyun Skor Tablosu" {...modalProps}>
      <Stack align="center" mih={300}>
        <Stack align="center" w={"100%"}>
          {scores?.map((user, index) => (
            <LeaderBoardUser key={user.username+"_"+index} avatar={user.avatar} order={index+1} score={user.score} isMe={user.isMe} username={user.username}/>
          ))}
        </Stack>
        {onRefresh && (
          <Button loading={isRefreshLoading ?? false} onClick={onRefresh} w={"fit-content"} mt={"auto"}>
            Yenile
          </Button>
        )}
      </Stack>
    </Modal>
  );
}
