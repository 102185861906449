// Authenticator.tsx
import { useWindowEvent } from "@mantine/hooks";
import React, { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import avatarGenerator from "./utils/avatar";
import usernameGenerator from "./utils/username";

const Authenticator: React.FC = () => {
  const initializeUser = () => {
    if (Object.keys(JSON.parse(window.localStorage.getItem("psr.me")||"{}")).length===0) {
      window.localStorage.setItem(
        "psr.me",
        JSON.stringify({
          username: usernameGenerator(),
          uuid: uuidv4(),
          avatar: avatarGenerator(),
          isFirstTime: true,
        })
      );
    }
  };
  useEffect(() => {
    initializeUser();
  }, []);

  useWindowEvent("hashchange", initializeUser);

  return null; // This component doesn't render anything
};

export default Authenticator;
