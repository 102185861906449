import {
  CSSVariablesResolver,
  MantineProvider,
  MantineTheme,
  VariantColorsResolver,
  createTheme,
  defaultVariantColorsResolver
} from "@mantine/core";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const variantColorResolver: VariantColorsResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input);

  // Override some properties for variant
  if (input.variant === "invert") {
    return {
      ...defaultResolvedColors,
      background: "var(--mantine-color-dark-7)",
      color: "var(--mantine-color-PSRYellow-5)",
      hoverColor: "var(--mantine-color-dark-7)",
      hover: "var(--mantine-color-PSRYellow-5)",
    };
  }
  return defaultResolvedColors;
};

const resolver: CSSVariablesResolver = (theme: MantineTheme) => ({
  variables: {
    "--mantine-color-body": "red",
  },
  light: {
    // '--mantine-color-deep-orange': theme.other.deepOrangeLight,
  },
  dark: {
    "--mantine-color-body": theme.colors.dark[6],
  },
});

const theme = createTheme({
  variantColorResolver: variantColorResolver,
  primaryColor: "PSRYellow",
  autoContrast: true,
  primaryShade: 5,
  components: {
    Button: {
      defaultProps: {},
    },
    TextInput: {
      defaultProps: {
        styles: {
          input: { fontSize: "16px" },
        },
      },
    },
  },
  colors: {
    body: [
      "#f3f3fe",
      "#e4e6ed",
      "#c8cad3",
      "#a9adb9",
      "#9093a4",
      "#808496",
      "#767c91",
      "#656a7e",
      "#585e72",
      "#4a5167",
    ],
    PSRYellow: [
      "#ffffe2",
      "#fffdcc",
      "#fffa9b",
      "#fff764",
      "#fff539",
      "#fff31d",
      "#fff209",
      "#e3d700",
      "#c9bf00",
      "#ada500",
    ],
    PSRGray: [
      "#f3f3fe",
      "#e4e6ed",
      "#c8cad3",
      "#a9adb9",
      "#9093a4",
      "#808496",
      "#767c91",
      "#656a7e",
      "#585e72",
      "#4a5167",
    ],
    PSRGreen: [
      "#effbf3",
      "#def5e6",
      "#b6eac9",
      "#8cdfaa",
      "#6ad68f",
      "#56d07f",
      "#4acf76",
      "#3bb665",
      "#31a258",
      "#218c49",
    ],
    PSRRed: [
      "#fdecec",
      "#f5d6d6",
      "#efa8a8",
      "#ea7877",
      "#e6504d",
      "#e43832",
      "#e42d25",
      "#ca211a",
      "#b41a15",
      "#9e1010",
    ],
  },
  /** Put your mantine theme override here */
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <MantineProvider
      defaultColorScheme="dark"
      theme={theme}
      cssVariablesResolver={resolver}
    >
      <Notifications />
      <ModalsProvider>
        <App />
      </ModalsProvider>
    </MantineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
