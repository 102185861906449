/* eslint-disable react-hooks/exhaustive-deps */
import {
  ActionIcon,
  Badge,
  Button,
  Center,
  Divider,
  LoadingOverlay,
  Skeleton,
  Stack,
  TextInput,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useListState } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { IconRefresh, IconX } from "@tabler/icons-react";
import { KeyboardEvent, useEffect, useState } from "react";
import API from "../api/api";
import { QuestionResponse } from "../models/QuestionResponse";
import shuffle from "../utils/shuffle";
import { httpsCookies } from "./Login";

enum Status {
  LOADING,
  NORMAL,
}
const DEFAULT = Array(15).fill({ word: "", meanings: [], shuffled: "" });
export default function AdminPanel() {
  const [questions, questionHandler] = useListState<QuestionResponse>(DEFAULT);
  const now = new Date();
  const api = new API();
  const [date, setDate] = useState<Date | null>(now);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [randomLoading, setRandomLoading] = useState(false);
  const [status, setStatus] = useState(Status.LOADING);

  const meaningsHandler = (
    index: number,
    event: KeyboardEvent<HTMLInputElement>
  ) => {
    const temp = [...questions[index].meanings];
    temp.push(event.currentTarget.value);
    event.currentTarget.value = "";
    questionHandler.setItemProp(index, "meanings", temp);
  };
  const wordHandler = (text: string, index: number) => {
    questionHandler.setItemProp(index, "word", text);
    questionHandler.setItemProp(index, "shuffled", shuffle(text));
  };
  const meaningsDeleteHandler = (index: number, mindex: number) => {
    let temp = [...questions[index].meanings];
    temp = temp.filter((e, eindex) => eindex !== mindex);
    questionHandler.setItemProp(index, "meanings", temp);
  };
  const getRandomQuestionsHandler = () => {
    setLoading(true);
    setRandomLoading(true);
    api
      .getQuestions(15)
      .then((res) => {
        if (res.data.length > 0) {
          questionHandler.setState(res.data);
        } else {
          questionHandler.setState(DEFAULT);
        }
      })
      .finally(() => {
        setLoading(false);
        setRandomLoading(false);
      });
  };
  const resetHandler = () => {
    questionHandler.setState(DEFAULT);
  };
  const submitQuestionsHandler = () => {
    setSubmitLoading(true);
    api
      .postDailyQuestions(
        questions === DEFAULT ? [] : questions,
        date?.getTime()
      )
      .then((res) => {
        notifications.show({
          title: "Sorular Başarıyla Yüklendi",
          message: `Sorular başarıyla ${date?.getDate()}/${
            (date?.getMonth() ?? -1) + 1
          }/${date?.getFullYear()} tarihine yüklendi.`,
          color: "teal",
        });
      })
      .catch(() => {
        notifications.show({
          title: "Sorular Yüklenirken Hatayla Karşılaşıldı",
          message: `Sorular sisteme yüklenemedi. Lütfen daha sonra tekrar deneyin.`,
          color: "red",
        });
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    api
      .getDailyQuestions(
        date?.getTime(),
        "00000000-0000-0000-0000-000000000000",
        true
      )
      .then((res) => {
        if (res.data.length > 0) {
          questionHandler.setState(res.data);
        } else {
          questionHandler.setState(DEFAULT);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [date]);

  useEffect(() => {
    api
      .checkToken()
      .then((res) => {
        httpsCookies.set("logged_in", res.data.isLoggedIn);
        if (!res.data.isLoggedIn) {
          window.location.hash = "#login";
          return;
        } else {
          setStatus(Status.NORMAL);
        }
      })
      .catch((err) => {
        httpsCookies.set("logged_in", false);
        httpsCookies.remove("token");
        window.location.hash = "#login";
      });
  }, []);

  return (
    <Center p={"xl"}>
      <LoadingOverlay
        overlayProps={{
          radius: "sm",
          blur: 2,
          color: "var(--mantine-color-dark-5)",
          backgroundOpacity: 1,
        }}
        visible={status === Status.LOADING}
      />
      <Stack>
        <Stack
          gap={"xl"}
          p={"xl"}
          justify="center"
          align="center"
          bg={"dark.8"}
          w={{ base: 300, sm: 400, md: 500, lg: 700 }}
        >
          <DateInput
            w={"100%"}
            value={date}
            onChange={setDate}
            valueFormat="DD/MM/YYYY"
            label="Tarih"
            placeholder="Tarihi Seçiniz"
            error={
              date?.getDate() === now.getDate() &&
              date.getMonth() === now.getMonth() &&
              date.getFullYear() === now.getFullYear() &&
              "Kaydettiğiniz sorular halihazırda devam eden günlük oyunun soruları yerine geçecektir."
            }
          />
          <Stack w={"100%"} gap={"sm"}>
            <Button
              loading={randomLoading}
              onClick={getRandomQuestionsHandler}
              bg={"dark.9"}
              fullWidth
              variant="invert"
            >
              Soruları Rastgele Getir
            </Button>
            <Button onClick={resetHandler} fullWidth variant="light">
              Sıfırla
            </Button>
          </Stack>

          {questions.map((question, index) => (
            <Stack key={"words_" + index} gap={0} w={"100%"}>
              <Divider label={`Soru ${index + 1}`} />

              <Stack gap={5} w={"100%"}>
                <Skeleton visible={loading}>
                  <TextInput
                    maxLength={7}
                    size="xs"
                    label={"Kelime"}
                    rightSection={
                      <ActionIcon
                        onClick={() => {
                          api
                            .getRandom()
                            .then((res) => {
                              questionHandler.setItem(index, res.data);
                            })
                            .catch(() => {
                              notifications.show({
                                message:
                                  "Lütfen internet bağlantınızı kontrol edip daha sonra tekrar deneyin.",
                                title: "Bir hatayla karşılaşıldı",
                              });
                            });
                        }}
                        variant="light"
                        size={"sm"}
                      >
                        <IconRefresh />
                      </ActionIcon>
                    }
                    value={question.word}
                    onChange={(event) => {
                      wordHandler(event.target.value, index);
                    }}
                  />
                </Skeleton>
                <Skeleton visible={loading}>
                  <TextInput
                    readOnly
                    size="xs"
                    value={question.shuffled}
                    rightSection={
                      <ActionIcon
                        onClick={() => {
                          questionHandler.setItemProp(
                            index,
                            "shuffled",
                            shuffle(question.shuffled)
                          );
                        }}
                        size={"sm"}
                        variant="light"
                      >
                        <IconRefresh />
                      </ActionIcon>
                    }
                    label={"Püsür"}
                  />
                </Skeleton>
                <Skeleton visible={loading}>
                  <TextInput
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === "Tab") {
                        if (e.currentTarget.value !== "") {
                          meaningsHandler(index, e);
                        }
                      }
                    }}
                    size="xs"
                    label={"Anlam(lar)ı"}
                  ></TextInput>
                  <Stack pt={5} gap={5}>
                    {question.meanings.map((m, mindex) => (
                      <Badge
                        styles={{
                          root: {
                            textTransform: "unset",
                            height: "fit-content",
                          },
                          label: { whiteSpace: "break-spaces" },
                        }}
                        color="dark"
                        key={m.replace(" ", "_") + "_" + mindex}
                        rightSection={
                          <ActionIcon
                            tabIndex={-1}
                            onClick={() => meaningsDeleteHandler(index, mindex)}
                            color="dark"
                            radius={"xl"}
                            size={13}
                          >
                            <IconX />
                          </ActionIcon>
                        }
                      >
                        {m}
                      </Badge>
                    ))}
                  </Stack>
                </Skeleton>
              </Stack>
            </Stack>
          ))}
        </Stack>
        <Button
          style={{
            position: "sticky",
            bottom: 20,
            boxShadow: "0px 0px 20px 3px var(--mantine-color-dark-9)",
          }}
          loading={submitLoading}
          color={
            questions.filter(
              (question) =>
                question.word !== "" || question.meanings.length !== 0
            ).length === 0
              ? "PSRRed.9"
              : ""
          }
          onClick={submitQuestionsHandler}
          disabled={
            questions.filter(
              (question) =>
                question.word === "" || question.meanings.length === 0
            ).length > 0 &&
            questions.filter(
              (question) =>
                question.word !== "" || question.meanings.length !== 0
            ).length > 0
          }
        >
          {questions.filter(
            (question) => question.word !== "" || question.meanings.length !== 0
          ).length === 0
            ? "Bugündeki Tüm Soruları Sil"
            : "Soruları Kaydet ve Gönder"}
        </Button>
      </Stack>
    </Center>
  );
}
