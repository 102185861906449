import {
  ActionIcon,
  Box,
  Button,
  Center,
  Flex,
  Group,
  Modal,
  PinInput,
  Popover,
  RingProgress,
  ScrollArea,
  Stack,
  Text,
  TextInput,
  Title,
  Tooltip,
  em,
} from "@mantine/core";
import {
  useInterval,
  useListState,
  useMediaQuery,
  useScrollIntoView,
  useWindowEvent,
} from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { TourGuideClient } from "@sjmc11/tourguidejs";
import "@sjmc11/tourguidejs/src/scss/tour.scss"; // Styles
import {
  IconArrowForward,
  IconArrowsShuffle,
  IconVocabulary,
  IconX,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import EndModal from "../components/EndModal";
import { IQuestions } from "../models/QuestionResponse";
import "../styles/tour.css";
import shuffle from "../utils/shuffle";
import timerDisplay from "../utils/timerDisplay";
import { tourQuestions } from "../utils/tourUtils";

const GROW = 10;
const DURATION = 240;
enum Status {
  ERROR,
  LOADING,
  TOUR_STARTED,
  TOUR_NOT_STARTED,
  TOUR_ENDED,
  NOT_STARTED,
  STARTED,
  ENDED,
  DAILY_ENDED,
}
export default function Tour(): JSX.Element {
  const me = JSON.parse(window.localStorage.getItem("psr.me") || "{}");
  const [questions, questionHandler] = useListState<IQuestions>(tourQuestions);
  const [addedScore, setAddedScore] = useState(GROW);
  const [current, setCurrent] = useState(0);
  const [status, setStatus] = useState(Status.LOADING);
  const [answer, setAnswer] = useState("");
  const [inputNotifColor, setInputNotifColor] = useState<string | undefined>();
  const [score, setScore] = useState(0);
  const [stack, setStack] = useState(-1);
  const [countDown, setCountDown] = useState(DURATION);
  const [timer, setTimer] = useState<string>("04:00");
  const [bonusScore, setBonusScore] = useState(0);
  const [openedMeaningsPopover, setMeaningsPopover] = useState(false);
  const interval = useInterval(() => setCountDown((s) => s - 1), 1000);
  const isSmallScreen = useMediaQuery(`(max-width: ${em(750)})`);
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLInputElement>({
    offset: 60,
  });
  const [tg, setTg] = useState<TourGuideClient | undefined>();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setAddedScore(GROW + (stack <= 0 ? 0 : stack * 5));
  }, [stack]);

  useWindowEvent("popstate", (e) => {
    if (tg) {
      window.location.reload();
      tg.finishTour();
    }
  });

  const scrollToTop = () => {
    setTimeout(() => {
      scrollIntoView({
        alignment: "end",
      });
    }, 400);
  };

  useEffect(() => {
    let tempTg: TourGuideClient | null = null;

    if (!tg) {
      tempTg = new TourGuideClient({
        debug: false,
        nextLabel: "Devam",
        prevLabel: "Önceki",
        finishLabel: "Bitir",
        dialogClass: "tourdialog",
        exitOnClickOutside: false,
        allowDialogOverlap: true,
        closeButton: false,
        steps: [
          {
            title: "👋 Hoş Geldin",
            content:
              "Püsür'e hoş geldin! Püsür, karışık kelimeleri doğru tahmin etmeye çalıştığın bir kelime oyunudur.",
          },
          {
            target: "#questions_ring",
            title: "❓ Mevcut İlerleme",
            content: "Her oyun 15 kelimeden oluşur.",
          },
          {
            target: "#timer_display",
            title: "⌛️ Sayaç",
            content: "Tüm soruları cevaplamak için 4 dakikan var.",
          },
          {
            target: "#score_display",
            title: "✅ Puan Durumu",
            content:
              "Her doğru cevap 10 puan kazandırır. Ardışık olarak 2 kelimeyi doğru cevaplarsan yük kazanmaya başlarsın. Kazandığın her yük başına ekstra 5 puan kazanırsın. Ancak unutma, en fazla 4 yük biriktirebilirsin. Yanlış cevap verirsen yüklerin sıfırlanır. Süren bitmeden oyunu tamamlarsan artırdığın zamanına oranla bonus puan kazanırsın.",
          },
          {
            target: "#shuffled_input",
            title: "🔀 Püsür",
            content:
              "Her soru, karşına karışık harflerle oluşturulmuş bir kelime şeklinde çıkar. Senden bu harflerin oluşturduğu doğru kelimeyi bulman istenir.",
          },
          {
            title: "🃏 Jokerler",
            target: "#jokers",
            content:
              "Sıkıştığın yerde jokerlerini kullanabilirsin. Karıştır jokeri (soldaki) ile harfleri karıştırıp soruya farklı açıdan bakabilirsin. Anlamını gör jokeri (sağdaki) ile kelimenin anlamını görüp çağrıştırmasını sağlayabilirsin. Jokerlerini istediğin kadar kullanabilirsin, fakat anlamını gör jokerini kullandığında yüklerin sıfırlanır.",
          },
          {
            title: "⌨️ Cevapla",
            target: "#text_input",
            content:
              "Cevaplarını buraya yazıp, 'Enter' tuşuna basarak veya sağdaki butona tıklayarak gönderebilirsin. Dilersen pas geçebilir ve süre kaldığı takdirde geri dönebilirsin.",
          },
          {
            title: "⚡️ Komutlar",
            content:
              "Oyunda hız kazanmak için aşağıdaki komutları kullanmak isteyebilirsin.: <br/><br/>Bitirmek için: 'bitir'<br/>Pas geçmek için: 'pas'<br/>Jokerleri kullanmak için: [joker_numarası]<br/> - Karıştırma jokeri için: '1'<br/> - Anlamını gör jokeri için: '2'<br/><br/> Bu komutları cevaplama yerine yazarak gönderip kullanabilirsin",
          },
          {
            title: "🎉 Hazırsın",
            content:
              "Artık hazırsın! Dilersen bu oyunu tamamlayıp bir sonraki oyunlara hazırlanabilirsin. Dilersen de 'bitir' yazarak ana menüye çıkabilirsin. İyi Şanslar!",
          },
        ],
      });
      setTg(tempTg);
    }

    return () => {
      // Cleanup previous instance
      if (tempTg) {
        tempTg.finishTour();
      }
    };
  }, []);

  useEffect(() => {
    if (status !== Status.TOUR_STARTED && tg) {
      tg.start();
      tg.onFinish(() => {
        if (me.isFirstTime) {
          me.isFirstTime = false;
          window.localStorage.setItem("psr.me", JSON.stringify(me));
        }
        setStatus(Status.STARTED);
        interval.start();
      });
      setStatus(Status.TOUR_STARTED);
    }
  }, [tg]);

  useEffect(() => {
    if (countDown === 0 && status === Status.STARTED) {
      setStatus(Status.ENDED);
    } else if (status === Status.STARTED) {
      const minutes = Math.floor(countDown! / 60);
      const seconds = Math.floor(countDown! % 60);
      setTimer(timerDisplay(minutes, seconds));
    }
  }, [countDown]);

  useEffect(() => {
    if (questions[current]?.isCorrect) {
      let temp = current;
      setCurrent(temp + 1);
    }
    if (current === (questions?.length || 0) && current !== 0) {
      if (questions.filter((question) => !question.isCorrect).length === 0) {
        setStatus(Status.ENDED);
      }
      setCurrent(questions?.findIndex((question) => !question.isCorrect));
    }
    setMeaningsPopover(false);
  }, [current]);

  useEffect(() => {
    if (status === Status.ENDED) {
      interval.stop();
      let bonus = bonusScore;
      if (questions.filter((q) => !q.isCorrect).length === 0) {
        bonus = Math.ceil(countDown / 5);
        setBonusScore(bonus);
      }
    }
  }, [status]);
  const checkHandler = () => {
    if (
      answer.toLocaleLowerCase("tr") ===
      questions[current]?.word.toLocaleLowerCase("tr")
    ) {
      setInputNotifColor("var(--mantine-color-PSRGreen-5)");
      setCurrent((prev) => prev + 1);
      setScore((prev) => prev + addedScore);
      setStack((prev) => (prev < 4 ? prev + 1 : prev));
      questionHandler.setItemProp(current, "isCorrect", true);
    } else if (answer.toLowerCase() === "pas" || answer.toLowerCase() === "") {
      setInputNotifColor("var(--mantine-color-dark-2)");
      setCurrent((prev) => prev + 1);
    } else if (answer.toLocaleLowerCase("tr") === "bitir") {
      openEndModal();
    } else if (answer.trim() === "1") {
      shuffleHandler();
    } else if (answer === "2") {
      meaningsHandler();
    } else {
      setInputNotifColor("var(--mantine-color-PSRRed-5)");
      setStack(-1);
    }
    setAnswer("");
    setTimeout(() => {
      setInputNotifColor(undefined);
    }, 1000);
  };

  const meaningsHandler = () => {
    setStack(stack > 0 ? -1 : stack);
    questionHandler.setItemProp(current, "areMeaningsVisible", true);
  };

  const shuffleHandler = () => {
    const temp = questions[current]?.shuffled;
    questionHandler.setItemProp(current, "shuffled", shuffle(temp));
  };

  const openEndModal = () =>
    modals.openConfirmModal({
      title: "Oyunu Sonlandırıyorsunuz",
      children: (
        <Text size="sm">Oyunu sonlandırmak istediğinize emin misiniz?</Text>
      ),
      labels: { confirm: "Evet", cancel: "Hayır" },
      onCancel: () => {},
      onConfirm: () => {
        setStatus(Status.ENDED);
      },
    });

  const AnswerButton = (): JSX.Element => {
    const props = {
      size: isSmallScreen ? "md" : "lg",
      style: { transition: "all 150ms" },
      onClick: () => {
        checkHandler();
      },
      onPointerDown: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
      ) => {
        event.preventDefault();
      },
      variant: "filled",
      p: 0,
    };

    switch (answer.toLocaleLowerCase("tr").trim()) {
      case "bitir":
        return (
          <ActionIcon {...props} color="PSRRed.5">
            <IconX />
          </ActionIcon>
        );
      case "1":
        return (
          <ActionIcon {...props} color="PSRGreen">
            <IconArrowsShuffle />
          </ActionIcon>
        );
      case "2":
        return (
          <ActionIcon {...props} color="PSRGreen">
            <IconVocabulary />
          </ActionIcon>
        );
      case "":
      case "pas":
        return (
          <ActionIcon {...props} color="dark.3">
            <Text fw={600} size="xs">
              PAS
            </Text>
          </ActionIcon>
        );
      default:
        return (
          <ActionIcon {...props}>
            <IconArrowForward />
          </ActionIcon>
        );
    }
  };

  const startHandler = async () => {
    setCountDown(DURATION);
    interval.start();
    setStatus(Status.STARTED);
    setCurrent(0);
    setScore(0);
    setStack(-1);
  };

  return (
    <div>
      <Modal
        opened={status === Status.NOT_STARTED}
        withCloseButton={false}
        onClose={() => {}}
        title={<Title order={5}>Oyunu Başlat</Title>}
        scrollAreaComponent={ScrollArea.Autosize}
      >
        <Stack>
          {/* <StartModal /> */}
          <Button onClick={startHandler}>Turu Başlat</Button>
          <Button
            variant="outline"
            color="dark"
            component="a"
            onClick={startHandler}
          >
            Turu Atla
          </Button>
        </Stack>
      </Modal>
      <Modal
        opened={status === Status.ENDED || status === Status.DAILY_ENDED}
        withCloseButton={false}
        onClose={() => {}}
        title={<Text fw={700}>Sonuçlar</Text>}
        scrollAreaComponent={ScrollArea.Autosize}
      >
        <Stack>
          <EndModal
            questions={questions}
            score={score}
            timer={DURATION - countDown}
            type={"playground"}
            bonusScore={bonusScore}
          />
          <Button variant="outline" color="dark" component="a" href="/">
            Ana Sayfaya Dön
          </Button>
        </Stack>
      </Modal>
      <Flex pl={"xl"} pr={"xl"} h={"100dvh"} justify={"center"}>
        <Stack
          w={"500px"}
          gap={isSmallScreen ? 0 : "md"}
          align="center"
          h={"100%"}
        >
          <Flex justify={"center"} align={"center"}>
            <Box pt={isSmallScreen ? "xs" : "xl"}>
              <RingProgress
                id="questions_ring"
                m={"lg"}
                roundCaps
                label={
                  <Center h={isSmallScreen ? 65 : 110}>
                    <Stack gap={0}>
                      <Text lh={"xs"} size="xs">
                        Soru
                      </Text>
                      <Text lh={"xs"} mt={"auto"} size={"xl"} ta="center">
                        {current + 1}
                      </Text>
                    </Stack>
                  </Center>
                }
                sections={
                  questions.length > 0
                    ? questions.map((question, index, array) => {
                        return {
                          value: 100 / array.length,
                          color:
                            current === index
                              ? "dark.2"
                              : question.isCorrect
                              ? "PSRGreen"
                              : "dark.0",
                        };
                      })
                    : [{ value: 100, color: "dark.0" }]
                }
              />
            </Box>
          </Flex>

          <Text id="timer_display">{timer}</Text>
          <Group
            pl={"xl"}
            pr={"xl"}
            id="score_display"
            w={"fit-content"}
            ta={"center"}
          >
            <Text
              styles={{
                root: {
                  display: "inline-block",
                  position: "relative",
                  transition: "all 100ms",
                },
              }}
              c={
                typeof inputNotifColor === "string" ? inputNotifColor : "dark.0"
              }
              size={"45px"}
              ta={"center"}
            >
              {score}
            </Text>
            <Text
              c={`PSRGreen.${5 + stack < 10 ? 5 + stack : 9}`}
              styles={{
                root: {
                  position: "absolute",
                  marginLeft: score.toString().length * 27,
                },
              }}
              ta={"right"}
              size="17px"
            >
              +{addedScore}
            </Text>
          </Group>

          <Stack
            mt={"5dvh"}
            h={"100%"}
            justify="center"
            align="center"
            gap={"xl"}
          >
            <Stack justify="center" align="center">
              <PinInput
                ref={targetRef}
                id="shuffled_input"
                placeholder=""
                defaultValue="PÜSÜR"
                gap={5}
                size={isSmallScreen ? "md" : "xl"}
                value={questions[current]?.shuffled.toLocaleUpperCase("tr")}
                length={questions[current]?.shuffled.length ?? 5}
                readOnly
              ></PinInput>
              <Group id="jokers">
                <Tooltip position="left" label={"Karıştır"}>
                  <ActionIcon
                    variant="outline"
                    color="PSRGreen"
                    size={isSmallScreen ? "md" : "lg"}
                    onPointerDown={(event) => {
                      event.preventDefault();
                    }}
                    onClick={shuffleHandler}
                  >
                    <IconArrowsShuffle />
                  </ActionIcon>
                </Tooltip>
                <Popover
                  opened={openedMeaningsPopover}
                  onChange={setMeaningsPopover}
                  width={300}
                  position="bottom"
                  withArrow
                  shadow="md"
                >
                  <Popover.Target>
                    <Tooltip position="right" label={"Anlamını gör"}>
                      <ActionIcon
                        color="PSRGreen"
                        variant={
                          questions[current]?.areMeaningsVisible || false
                            ? "outline"
                            : "filled"
                        }
                        onClick={(event) => {
                          event.stopPropagation();
                          meaningsHandler();
                        }}
                        onPointerDown={(event) => {
                          event.preventDefault();
                        }}
                        size={isSmallScreen ? "md" : "lg"}
                      >
                        <IconVocabulary />
                      </ActionIcon>
                    </Tooltip>
                  </Popover.Target>
                  <Popover.Dropdown
                    onClick={(event) => {
                      console.log(event);
                      event.preventDefault();
                    }}
                    onKeyDown={(event) => {
                      event.preventDefault();
                    }}
                  >
                    {(questions[current]?.areMeaningsVisible || false) && (
                      <Box
                        pl={"sm"}
                        pr={"sm"}
                        component={ScrollArea}
                        mah={isSmallScreen ? 150 : 140}
                      >
                        {questions[current]?.meanings.map(
                          (meaning, index, array) => (
                            <Text
                              key={meaning.trim().replaceAll(" ", "_")}
                              size={isSmallScreen ? "xs" : "sm"}
                              ta={"center"}
                            >
                              {array.length > 1 ? `${index + 1} - ` : ""}
                              {meaning}
                            </Text>
                          )
                        )}
                      </Box>
                    )}
                  </Popover.Dropdown>
                </Popover>
              </Group>
            </Stack>

            <Stack mt={"auto"} mb={"xl"}>
              <TextInput
                id={"text_input"}
                style={{
                  position: "absolute",
                  top:
                    (window.visualViewport?.height || window.innerHeight) - 60,
                  marginLeft: "auto",
                  left: 0,
                  right: 0,
                  marginRight: "auto",
                  width: 300,
                }}
                mb={"md"}
                size="md"
                onKeyDown={(event) => {
                  setMeaningsPopover(false);
                  if (event.key === "Enter") {
                    checkHandler();
                  }
                }}
                onFocus={(event) => {
                  scrollToTop();
                  event.preventDefault();
                }}
                onChange={(event) => setAnswer(event.target.value.trim())}
                value={answer}
                rightSection={<AnswerButton />}
                placeholder="Pas"
                styles={{
                  input: {
                    border:
                      typeof inputNotifColor === "string"
                        ? `1px ${inputNotifColor} solid`
                        : "",
                    transition: "all 100ms ease-out",
                  },
                }}
                ta={"center"}
                autoFocus
              ></TextInput>
            </Stack>
          </Stack>
        </Stack>
      </Flex>
    </div>
  );
}
