export const avatars = [
  "Bubba",
  "Bob",
  "Lucky",
  "Kiki",
  "Kitty",
  "Chloe",
  "Baby",
  "Pepper",
  "Cuddles",
  "Lola",
  "Molly",
  "Chester",
  "Toby",
  "Oliver",
  "Socks",
  "Lilly",
  "Abby",
  "Loki",
  "Mimi",
  "Jasmine",
];
export default function avatarGenerator() {
  return avatars[Math.floor(Math.random() * avatars.length)];
}

export function avatarURL(alias:string){
  return `https://api.dicebear.com/7.x/adventurer/svg?seed=${alias}&backgroundType=gradientLinear&backgroundColor=ffdfbf,ffd5dc`
}
