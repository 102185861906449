import { Divider, Stack, Text, Title } from "@mantine/core";
interface IStartModal {
  type: "home" | "daily" | "playground";
}
export default function StartModal({ type }: IStartModal) {
  return (
    <Stack>
      <Stack gap={"xs"}>
        <Title order={6}>Püsür</Title>
        <Text size={"sm"}>
          Püsür, karışık kelimeleri doğru tahmin etmeye çalıştığın bir kelime
          oyunudur.
        </Text>
        {(type === "daily" || type === "home") && (
          <div>
            <Divider label={"Günlük Oyun Modu"} />
            <Text size={"sm"}>
              <b>Günlük oyun</b> rekabetçi bir moddur. Sorular her güne özel
              hazırlanır ve oyun sonunda skorlarınız günlük oyun skorları
              tablosunda görüntülenir.
            </Text>
          </div>
        )}
        {(type === "playground" || type === "home") && (
          <div>
            <Divider label={"Normal Oyun Modu"} />
            <Text size={"sm"}>
              <b>Normal oyun,</b> günlük oyuna göre biraz daha zor olabilir.
              Sözlükten alınmış, kriterlere uygun 5000 kelimelik havuzdan
              rastgele sorular seçilir.
            </Text>
          </div>
        )}
        {type === "home" && (
          <div>
            <Divider label={"Oynanış"} />
            <Text size={"sm"}>
              Her oyun <b>15 kelimeden</b> oluşur. Bu kelimeleri doğru tahmin
              etmek için <b>4 dakikanız</b> bulunuyor.
            </Text>
            <Text size={"sm"}>
              Bu süre zarfında jokerlerinizi kullanabilirsiniz.
            </Text>
            <Text size={"sm"}>
              <span
                style={{
                  color: "var(--mantine-color-PSRYellow-8)",
                  fontWeight: 500,
                }}
              >
                1- Karıştır
              </span>{" "}
              jokeri harflerin sırasını karıştırarak karışık kelimeye başka
              açıdan bakmanızı sağlar.
            </Text>
            <Text size={"sm"}>
              <span
                style={{
                  color: "var(--mantine-color-PSRYellow-8)",
                  fontWeight: 500,
                }}
              >
                2- Anlamını Gör
              </span>{" "}
              jokeri doğru kelimenin sözlükteki anlam(lar)ını gösterir. Bu
              sayede cevaba bir adım daha yaklaşırsınız.
            </Text>
            {/* <Text size={"sm"}>
          <span
            style={{
              color: "var(--mantine-color-dark-0)",
              fontWeight: 500,
            }}
          >
            Pas
          </span>{" "}
          yazarak veya hiçbir şey yazmadan gönder butonuna basarak soruyu
          geçebilirsiniz. Süreniz kalırsa soruya tekrar dönüp
          cevaplayabilirsiniz.
        </Text> */}
            <Text size={"sm"}>
              <span
                style={{
                  color: "var(--mantine-color-PSRGreen-5)",
                  fontWeight: 500,
                }}
              >
                Bonus Yükleri
              </span>{" "}
              2 soru art arda bildiğiniz zaman devreye girer. Kelime başına 10
              puan alırken, yükler devreye girdikten sonra bildiğiniz her kelime
              başına 5 puan daha kazanırsınız. Fakat bir kelimeyi yanlış bilmek
              veya anlamını gör jokerini kullanmak yüklerinizi sıfırlar.
            </Text>
            <Text size="sm">
              Tüm kelimeleri 2 dakikadan kısa sürede tamamlarsanız{" "}
              <b>artırdığınız zamana oranla bonus puan kazanırsınız.</b>
            </Text>
          </div>
        )}
        <Divider label={"Komutlar"} />
        <Stack gap={5}>
          <Text size="xs" c={"PSRRed.5"}>
            Bitirmek için: "bitir"
          </Text>
          <Text size="xs">Pas geçmek için: "pas"</Text>
          <Stack gap={2}>
            <Text size="xs">Jokerleri kullanmak için: [joker_numarası]</Text>
            <Text pl={"xs"} fs={"italic"} size="xs">
              Örnek: "1" - Karıştır jokerini kullanır.
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
