import {
  Button,
  Center,
  Flex,
  Image,
  Paper,
  Stack,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useState } from "react";
import Cookies from "universal-cookie";
import API from "../api/api";
import logo from "../images/logo.svg";

export const httpsCookies = new Cookies(null, {
  path: "/",
  sameSite: "none",
  secure: true,
  httpOnly:false,
});

export default function Login() {
  const form = useForm({ initialValues: { username: "", password: "" } });
  const [loginLoading, setLoginLoading] = useState(false);
  const api = new API();
  const onSubmit = async () => {
    setLoginLoading(true);
    api
      .login(
        form.getInputProps("username").value,
        form.getInputProps("password").value
      )
      .then((res) => {
        httpsCookies.set("token", res.data.token);
        httpsCookies.set("logged_in", true);
        window.location.hash = "admin";
      })
      .catch(() => {
        notifications.show({
          title: "Kullanıcı adı veya şifre yanlış",
          message: "Bilgilerinizi kontrol edip tekrar deneyiniz.",
          color: "PSRRed",
        });
      })
      .finally(() => {
        setLoginLoading(false);
      });
  };

  return (
    <Center h={"100dvh"}>
      <Paper w={400} shadow="xl" bg={"dark.8"}>
        <Stack gap={"md"} p={"xl"}>
          <Flex
            direction={"column"}
            pt="md"
            justify={"center"}
            align={"center"}
            w={"100%"}
          >
            <Image src={logo} w={180}></Image>
          </Flex>
          <Title ta={"center"} order={4}>
            Admin Paneli
          </Title>
          <Stack gap={"xs"}>
            <TextInput
              type="text"
              {...form.getInputProps("username")}
              label={"Kullanıcı adı"}
              placeholder="john_doe"
            />
            <TextInput
              {...form.getInputProps("password")}
              label={"Şifre"}
              type="password"
              placeholder="●●●●●●●●"
            />
          </Stack>
          <Button loading={loginLoading} type="submit" onClick={onSubmit}>
            Giriş Yap
          </Button>
        </Stack>
      </Paper>
    </Center>
  );
}
