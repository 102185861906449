export const tourQuestions = [
  {
    word: "Ceku",
    shuffled: "ukec",
    areMeaningsVisible: false,
    isCorrect: false,
    meanings: ["'... Hazırsan çıkalım balım'"],
  },
  {
    word: "Oyun",
    shuffled: "yuno",
    areMeaningsVisible: false,
    isCorrect: false,
    meanings: [
      "Yetenek ve zeka geliştiri, belli kuralları olan, iyi vakit geçirmeye yarayan eğlence.",
    ],
  },
  {
    word: "Dilek",
    shuffled: "likde",
    areMeaningsVisible: false,
    isCorrect: false,
    meanings: ["Bir kişinin isteği veya arzusu."],
  },
  {
    word: "Tarih",
    shuffled: "rihat",
    areMeaningsVisible: false,
    isCorrect: false,
    meanings: [
      "Geçmişte meydana gelen olayların kronolojik sırasına göre incelendiği bilim dalı.",
    ],
  },
  {
    word: "Kalem",
    shuffled: "melak",
    areMeaningsVisible: false,
    isCorrect: false,
    meanings: ["Yazı yazmak veya çizim yapmak için kullanılan araç."],
  },
  {
    word: "Ekmek",
    shuffled: "kemke",
    areMeaningsVisible: false,
    isCorrect: false,
    meanings: ["Buğday veya diğer tahılların unundan yapılan yiyecek."],
  },
  {
    word: "Kuş",
    shuffled: "kşu",
    areMeaningsVisible: false,
    isCorrect: false,
    meanings: [
      "Genellikle tüyleri olan, kanatları ve gagası bulunan, uçabilen bir hayvan.",
    ],
  },
  {
    word: "Gökyüzü",
    shuffled: "zgökyüü",
    areMeaningsVisible: false,
    isCorrect: false,
    meanings: [
      "Yeryüzünün atmosferinin yukarısında yer alan ve mavi olarak görülen boşluk.",
    ],
  },
  {
    word: "Deniz",
    shuffled: "ziden",
    areMeaningsVisible: false,
    isCorrect: false,
    meanings: [
      "Bir veya birkaç gölü birleştiren, tuzlu su ile dolu olan büyük su kütlesi.",
    ],
  },
  {
    word: "Kedi",
    shuffled: "deki",
    areMeaningsVisible: false,
    isCorrect: false,
    meanings: ["Genellikle evlerde beslenen, tüylü, dört ayaklı bir hayvan."],
  },
  {
    word: "Kalemlik",
    shuffled: "melkilak",
    areMeaningsVisible: false,
    isCorrect: false,
    meanings: [
      "Kalemleri, silgileri ve diğer küçük ofis malzemelerini saklamak için kullanılan küçük bir kutu veya teneke.",
    ],
  },
  {
    word: "Kuşluk",
    shuffled: "lkkuuş",
    areMeaningsVisible: false,
    isCorrect: false,
    meanings: ["Sabahın erken saatlerinde gerçekleştirilen etkinliklerin adı."],
  },
  {
    word: "Yat",
    shuffled: "tay",
    areMeaningsVisible: false,
    isCorrect: false,
    meanings: [
      "Genellikle denizlerde veya nehirlerde yolcu veya yük taşıyan büyük bir tekne.",
    ],
  },
  {
    word: "Ayna",
    shuffled: "yaan",
    areMeaningsVisible: false,
    isCorrect: false,
    meanings: [
      "Işığın yansıması sayesinde görüntülerin yansıtıldığı cam veya metal yüzey.",
    ],
  },
  {
    word: "Pencere",
    shuffled: "pecenre",
    areMeaningsVisible: false,
    isCorrect: false,
    meanings: [
      "Duvar veya çatılarda, ışık almak, hava almak veya dışarıyı görmek için yapılan açıklık.",
    ],
  },
];
