import {
  Button,
  Collapse,
  Stack,
  Text
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconChevronDown,
  IconChevronUp
} from "@tabler/icons-react";
import { IQuestions } from "../models/QuestionResponse";
interface IAnswerRow {
  question: IQuestions;
  order: number;
}

export default function AnswerRow({ question, order }: IAnswerRow) {
  const [openedMeanings, { toggle }] = useDisclosure(false);
  return (
    <Stack gap={0}>
      <Button
        rightSection={
            openedMeanings ? <IconChevronUp /> : <IconChevronDown />
        }
        onClick={toggle}
        p={0}
        variant="transparent"
        color={question.isCorrect ? "PSRGreen.9" : "dark.0"}
        justify="space-between"
        ta={"left"}
        fullWidth
      >
        {order}
        {" - "}
        {question.word}
      </Button>
      <Collapse pl={"sm"} m={0} in={openedMeanings}>
        {question.meanings.map((meaning, index, array) => (
          <Text key={meaning.trim().replaceAll(" ", "_")} fs={"italic"} size="xs" ta={"left"}>
            {array.length > 1 ? `${index + 1} - ` : ""}
            {meaning}
          </Text>
        ))}
      </Collapse>
    </Stack>
  );
}
